<template>
    <div>
        <v-row align="center" justify="center">
            <v-col cols="3"/>
            <v-col cols="6"> <v-row><p><a href="http://assa.bionet.nsc.ru/en/department/f06e5c34-bc12-11e0-9960-00055d747152/>">Developed in Department of Molecular Genetics, Cell Biology, and
            Bioinformatics</a> of <a href="http://www.bionet.nsc.ru/en">Institute of Cytology and Genetics</a> by Victor Levitsky,
Alexey Mukhin, Dmitry Oshchepkov, Elena Zemlyanskaya and Sergey Lashin</p></v-row>
            <v-row justify="center"><img src="../../public/ICG-engl.png" style="max-width: 150px;" alt="ICG SB RAS LOGO"></v-row></v-col>
            <v-col cols="3"/>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: "Authors"
    }
</script>

<style scoped>

</style>