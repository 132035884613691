import { render, staticRenderFns } from "./Authors.vue?vue&type=template&id=7cd23067&scoped=true&"
import script from "./Authors.vue?vue&type=script&lang=js&"
export * from "./Authors.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-f90b86ed5d/0/cache/vue-loader-npm-15.9.3-4ca89c7e4b-eec86deeea.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd23067",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../.yarn/__virtual__/vuetify-loader-virtual-4599d4ed9c/0/cache/vuetify-loader-npm-1.6.0-0f109b0731-77ac57a22a.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
